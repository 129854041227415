
             
.app-main-menu {
 
  .v-list__tile {
    height: 36px;
  }
  
  .v-list__tile__title {
    max-width: 200px;
  }
}
