
             
.app-drawer {
    .v-avatar {
        justify-content: flex-end;
    }

    &.app-drawer--dense {
        .v-list__tile__action {
            min-width: 40px;
        }
    }
    &.app-drawer--hover {
        width: 400px!important;
        &.v-navigation-drawer--close {
            transform: translateX(-400px)!important;
        }
    }
}
