
             
.app-content {
  &&.app-content--center {
    .app-content__container {
      display: flex;
      margin: auto;
      height: 100%;
    }
  }
  &&.app-content--full_width {
    .app-content__container {
      max-width: 100%!important;
      height: 100%;
    }
  }
  &&.app-content--cut_width {
    .app-content__container {
      max-width: 1280px!important;
      height: 100%;
    }
  }
}
