
             @import "variables";

.v-content {
  background-color: white;
}

.v-list__tile {
  font-size: 14px !important;
}

.v-label {
  color:black !important;
  font-size: 14px !important;
}

.v-input--selection-controls.v-input--checkbox{
  margin-top: 0px;
}

.xsmall.v-btn {
  width: 28px!important;
  height: 28px!important;;
}
.btn--bottom.btn--absolute.xsmall {
  bottom: -17px;
}

.dense.v-chip {
  margin: 0;
}
.dense.v-chip .v-chip__content {
  height: 24px;
}

.app-card {
  padding: @component-padding;
}

.v-toolbar__title {
  a {
    color: #FFC107;
    text-decoration: none;
  }
}
.fude-dialog.v-dialog--scrollable {
  flex: inherit !important;
}

.app-hide-field-message {
  .v-text-field__details {
    display: none;
  }
}

.v-text-field.v-text-field--solo.dense
  .v-input__control {
    min-height: 32px;
  }

.v-text-field.v-text-field--solo  {
  .v-input__control {
    min-height: 38px !important;
  }
}


.app-from--hide-details {
  .v-text-field__details {
    display: none;
  }
  .v-input--switch {
    margin-top: 0;
    .v-input__slot {
      margin-bottom: 0;
    }
    .v-messages {
      display: none;
    }
  }
  .v-input__slot {
    margin-bottom: 0;
  }
  .v-input--checkbox {
    .v-messages {
      display: none;
    }
  }
  .app-from__field--show-details {
    .v-text-field__details {
      display: flex;
    }
  }
}

.app-dialog {
  margin: 0!important;
  padding: 0!important;

  .v-text-field__details {
    display: none;
  }
  .v-input--switch {
    margin-top: 0;
    .v-input__slot {
      margin-bottom: 0;
    }
    .v-messages {
      display: none;
    }
  }
  .v-input__slot {
    margin-bottom: 0;
  }
  .v-input--checkbox {
    .v-messages {
      display: none;
    }
  }

  .app-dialog__field--show-details {
    .v-text-field__details {
      display: flex;
    }
  }
}

.fude-table.fude-table--solo {
  box-shadow: none;
  border: 2px solid rgba(0,0,0,0.54)!important;
  border-radius: 4px;
  nav {
    box-shadow: none;
  }
}

.v-btn--text-transform-none {
  text-transform: none!important;
}

.fude-row {
  display: flex;
  .fude-column-star {
    display: flex;
    flex-grow: 1;
    margin: auto;
  }
  .fude-column-auto {
    display: flex;
    flex-grow: 0;
    margin: auto;
  }
}

.h-100 {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

.c-pointer {
  cursor: pointer;
}

.float-right {
  float: right !important;
}

.font-14 {
  font-size: 14px!important;
}

.text-center {
  text-align: center!important;
}